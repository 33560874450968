var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "o-wrapper work_filter__container" }, [
      _c(
        "ul",
        [
          _c(
            "li",
            {
              class: { current: _vm.checkShowAll() },
              on: {
                click: function($event) {
                  return _vm.filterItems(null)
                }
              }
            },
            [_c("span", [_vm._v("Show all")])]
          ),
          _vm._v(" "),
          _vm._l(_vm.categories, function(category, index) {
            return _c(
              "li",
              {
                class: { current: _vm.checkCategory(category.id) },
                on: {
                  click: function($event) {
                    return _vm.filterItems(category.id)
                  }
                }
              },
              [_c("span", [_vm._v(" " + _vm._s(category.title) + " ")])]
            )
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "o-wrapper work_content__container" }, [
      _c("div", { staticClass: "grid__container" }, [
        _c(
          "div",
          { staticClass: "grid__innercontainer" },
          [
            _vm._l(_vm.news, function(n, index) {
              return _c(
                "div",
                {
                  class: {
                    "grid__item-big": n.overviewItemsize,
                    "grid__item-small": !n.overviewItemsize
                  }
                },
                [
                  _c("a", { attrs: { href: n.url } }, [
                    _c("div", { staticClass: "grid__item-contentcontainer" }, [
                      _c("h3", [_vm._v(_vm._s(n.title))]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(n.caseTypeOverview))])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid__item-overlay-red" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid__item-overlay" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid__item-image" }, [
                      _c("img", { attrs: { src: n.overviewImageUrl } })
                    ])
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "grid__item-small" }),
            _vm._v(" "),
            _c("div", { staticClass: "grid__item-small" }),
            _vm._v(" "),
            _c("div", { staticClass: "grid__item-small" })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _vm.moreButtonVisible
      ? _c("div", { staticClass: "o-wrapper center buttonContainer" }, [
          _c(
            "button",
            {
              staticClass: "c-btn--primary",
              on: {
                click: function($event) {
                  return _vm.loadMoreNews()
                }
              }
            },
            [
              _vm.showSpinner == "loading"
                ? _c("span", [
                    _vm._v("\n                loading...\n            ")
                  ])
                : _vm.showSpinner == "error"
                ? _c("span", [_vm._v("\n                error\n            ")])
                : _c("span", [
                    _vm._v("\n                more work\n            ")
                  ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }