<template>
    <div>
        <section class="o-wrapper work_content__container">
                <div class="grid__container">
                    <div class="grid__innercontainer">
                            <div v-for="(n,index) in news" 
                                class="grid__item-small"
                            >   
                                <a v-bind:href="n.url">
                                    <div class="grid__item-contentcontainer">
                                        <h3>{{ n.title }}</h3>
                                        <p>{{ n.date }}</p>
                                    </div>
                                    <div class="grid__item-overlay-red"></div>
                                    <div class="grid__item-overlay"></div>
                                    <div class="grid__item-image">
                                        <img v-bind:src="n.overviewImageUrl"></img>
                                    </div>
                                </a>
                            </div>
                            <div class="grid__item-small"></div>
                            <div class="grid__item-small"></div>
                            <div class="grid__item-small"></div>
                    </div>
                </div>
        </section>
        <div v-if="moreButtonVisible" class="o-wrapper center buttonContainer">
            <button v-on:click="loadMoreNews()" class="c-btn--primary">
                <span v-if="showSpinner == 'loading'">
                    loading...
                </span>
                <span v-else-if="showSpinner == 'error'">
                    error
                </span>
                <span v-else>
                    more news
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import Vue from "vue"

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

export default {
    name: 'Loadmore',
    data () {
        return {
            nextPage: 2,
            showSpinner: '',
            news: [],
            filter: null,
            moreButtonVisible: true,
            section: 'newsEntries',
            openedOverlay: ''

        }
    },
    created: function () {

    },
    mounted () {

    },
    methods: {
        checkCategory: function (cat) {
            if (cat === this.filter) return true
        },
        checkShowAll: function () {
            if (this.filter === null | this.filter === '') return true
        },
        shortDescriptiom: function (d) {
            // TRUNCATE STRING
            let string = d.replace(/<[^>]*>/g, '')
            let s
            if (string.length > 250) s = string.slice(0, 250).concat('...')
            else s = string
            return s
        },
        filterItems: function (data) {
            let t = this
            let url

            if (data) {
                t.filter = data
            } else {
                t.nextPage = 1
                t.filter = ''
            }

            if (this.filter) url = '/newsEntries/' + this.filter + '.json'
            else url = '/newsEntries.json' + '?pg=' + this.nextPage

            axios.get(url)
                .then(function (response) {
                    // handle success
                    let data = response.data
                    t.news = []
                    t.news = t.news.concat(data.data)
                    
                    if (data.meta.pagination.total_pages > 1) {
                        t.nextPage = 2
                        t.moreButtonVisible = true
                    } else {
                        t.moreButtonVisible = false
                    }
                    
                })
                .catch(function (error) {
                    this.showSpinner = 'error'
                })
        },
        loadMoreNews: function () {
            this.showSpinner = 'loading'
            let url

            if (this.filter) url = '/newsEntries/' + this.filter + '.json' + '?pg=' + this.nextPage
            else url = '/newsEntries.json' + '?pg=' + this.nextPage

            let t = this

            axios.get(url)
                .then(function (response) {
                    // handle success
                    let data = response.data

                    t.news = t.news.concat(data.data)
                    t.nextPage ++

                    if (data.meta.pagination.total_pages === data.meta.pagination.current_page) t.moreButtonVisible = false
                    else t.moreButtonVisible = true

                    t.showSpinner = ''
                })
                .catch(function (error) {
                    this.showSpinner = 'error'
                })
        },
    }
}
</script>